footer {
    width: 100%;
    color: var(--footer-text-color);
    background-color: var(--footer-background-color);
    padding: var(--page-padding);
    padding-top: calc(3 * var(--page-padding));
    padding-bottom: calc(3 * var(--page-padding));
    gap: calc(2 * var(--page-padding));

    #jobs {
        .content {
            display: grid;
            grid-template-columns: 1fr 1fr 1fr 1fr;
            gap: 1em;
        }
    }

    #contact {
        .content {
            display: grid;
            grid-template-columns: 1fr 1fr;
            row-gap: 1em;
            column-gap: 3em;
        }
    }

    #imprint {
        .content {
            gap: 0px;

            h2 {
                margin-bottom: 0.2em;
                margin-top: 1em;
            }
        }
    }
}

@media screen and (max-width: $breakpointWide) {
    footer {
        section#jobs {
            .content {
                grid-template-columns: 1fr 1fr;
            }
        }
    }
}

@media screen and (max-width: $breakpoint) {
    footer {
        padding: var(--page-padding);
        gap: var(--page-padding);
    }
}
