main {
    padding-bottom: 0;

    section#image {
        .image-container {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            grid-column-start: 2;

            svg {
                width: 100%;
                max-width: 500px;
            }
        }
    }
}

#archive {
    background-color: var(--archive-background-color);
    border-top: 1px var(--archive-border-color) solid;
    border-bottom: 1px var(--archive-border-color) solid;
    padding-top: calc(2 * var(--page-padding));
    padding-bottom: calc(3 * var(--page-padding));
}

body.grid {
    main section[type="items"] .content {
        grid-template-columns: 1fr 1fr;
    }
}

@media screen and (max-width: $breakpoint) {
    #archive {
        padding: var(--page-padding);
    }
}
