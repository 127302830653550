* {
    margin: 0px;
    padding: 0px;
    bottom: 0px;
    box-sizing: border-box;

    p {
        display: block;
        text-align: justify;
        margin-top: 0.35em;
        margin-bottom: 0.5em;
    }
}

html,
body {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    font-family: "Jost", "Cascadia Code", "Cascadia", sans-serif;
    font-size: 16px;
    overflow: auto;
}

body {
    background-color: var(--page-background-color);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    align-content: flex-start;
    justify-content: flex-start;
    transition: background-color ease 300ms;

    &.init {
        animation-name: initialshow;
        animation-duration: 500ms;
    }
}

@keyframes initialshow {
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}
