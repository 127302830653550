$inner-wrapper-width: 1000px;
$breakpoint: 600px;
$breakpointWide: 840px;

:root {
    --font-weight-light: 300;
    --font-weight-regular: 400;
    --font-weight-medium: 500;
    --font-weight-semibold: 600;
    --font-weight-bold: 700;
    --font-weight-extrabold: 800;
    --font-weight-black: 900;

    --default-padding: 1em;
    --page-padding: calc(2.5 * var(--default-padding));

    @media screen and (max-width: $breakpointWide) {
        --page-padding: calc(2 * var(--default-padding));
    }

    @media screen and (max-width: $breakpoint) {
        --page-padding: calc(1.75 * var(--default-padding));
    }

    --page-background-color: #f5f5f5;
    --base-text-color: #222222;
    --link-background-color: #32327733;
    --header-greeting-text-color: #000000;
    --header-subjects-text-color: #333333;
    --header-toggle-text-color: #666666;
    --header-toggle-border-color: #dfdfdf;
    --archive-background-color: #f2f2f2;
    --archive-border-color: #dddddd;
    --footer-background-color: #efefef;
    --footer-headline-text-color: #000000;
    --footer-text-color: #555555;
    --footer-text-color-hover: #000000;
    --footer-links-text-color: #555555;
    --footer-links-text-color-hover: #000000;
    --section-title-text-color: #000000;
    --section-title-border-color: #e4e4e4;
    --item-background-color: #f8f8f8;
    --item-background-color-hover: #fbfbfb;
    --item-border-color: #e1e1e1;
    --item-border-color-hover: #dadada;
    --item-title-text-color: #000000;
    --item-subtitle-text-color: #777777;
    --event-title-text-color: var(--item-title-text-color);
    --event-subtitle-text-color: var(--item-subtitle-text-color);
    --event-date-and-place-text-color: #555555;
    --tag-text-color: #aaaaaa;
    --tag-border-color: #dddddd;
    --job-title-text-color: #000000;
    --job-subtitle-text-color: #777777;
    --job-date-color: #bbbbbb;
    --job-border-color: #eeeeee;

    --item-border-radius: 2px;
    --event-border-radius: var(--item-border-radius);

    body.dark {
        --page-background-color: #333333;
        --base-text-color: #eeeeee;
        --link-background-color: #32327733;
        --header-greeting-text-color: #ffffff;
        --header-subjects-text-color: #cccccc;
        --header-toggle-text-color: #999999;
        --header-toggle-border-color: #555555;
        --archive-background-color: #303030;
        --archive-border-color: #363636;
        --footer-background-color: #2e2e2e;
        --footer-headline-text-color: #ffffff;
        --footer-text-color: #cccccc;
        --footer-text-color-hover: #ffffff;
        --footer-links-text-color: #cccccc;
        --footer-links-text-color-hover: #ffffff;
        --section-title-text-color: #ffffff;
        --section-title-border-color: #4f4f4f;
        --item-background-color: #3b3b3b;
        --item-background-color-hover: #3e3e3e;
        --item-border-color: #484848;
        --item-border-color-hover: #5a5a5a;
        --item-title-text-color: #ffffff;
        --item-subtitle-text-color: #808080;
        --event-title-text-color: var(--item-title-text-color);
        --event-subtitle-text-color: var(--item-subtitle-text-color);
        --event-date-and-place-text-color: #cccccc;
        --tag-text-color: #bbbbbb;
        --tag-border-color: #555;
        --job-title-text-color: #ffffff;
        --job-subtitle-text-color: #808080;
        --job-date-color: #bbbbbb;
        --job-border-color: #484848;
    }
}
